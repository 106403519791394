import { slugify } from "utils/helpers";
import { api } from "./api";

export async function uploadFile(file?: any, extra?: any) {
  try {
    const payload = {
      file,
      extra
    }

    //form data
    const formData = new FormData();
    formData.append("file", payload.file);

    //rename the file
    // slugify the filename
    formData.append("filename", slugify(payload.file.name));

    if (payload.extra) {
      formData.append("extra", JSON.stringify(payload.extra));
    }

    const { data } = await api.post(`upload/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    return data;
  }
  catch (e) {
    console.log(e);
  }
}
