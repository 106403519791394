import { api } from "./api";

export async function submitRealLifeMission(data?: any) {
  try {
    const payload = {
      ...data
    }

    const result = await api.post(`database/real_life_log__c`, payload);

    return result.data;
  }
  catch (e) {
    console.log(e);
  }
}

export async function getRealLifeLog(missionId?: any, player?: any) {
  try {
    const result = await api.post(`find/real_life_log`, {
      missionId,
      player
    });
    return result.data;
  }
  catch (e) {
    console.log(e);
  }
}
