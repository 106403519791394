import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { QRCodeSVG } from "qrcode.react";
import { Button } from "flowbite-react";
import Gem from "components/gem";
import { useModal } from "context/ModalContext";
import { clearNotifications } from "services/notifications.service";

export function PurchaseModal(props: any) {
  ReactModal.setAppElement("#modal");

  const modalID = "purchase";

  const [modalIsOpen, setIsOpen] = useState(false);
  const { openModal, closeModal, modalData } = useModal();

  async function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {}

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 px-10 w-[450px] flex flex-col items-center flex-none mx-5 gap-3">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                Premio redimido
              </h1>

              {modalData ? (
                <div className="relative my-3">
                  <QRCodeSVG value={modalData._id} width={200} height={200} />
                </div>
              ) : null}

              <div>
                <p className="text-center">
                  Muestre este código QR
                  <br />
                  al administrador del premio
                </p>
              </div>

              <div className="py-3 relative z-10">
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  CERRAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
